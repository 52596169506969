// Here you can add other styles
.fade {
    opacity: 1;
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
    pointer-events: unset;
}

.fade.in {
    opacity: 0;
    pointer-events: unset;
}

.rs-popover {
    opacity: 1 !important;
}

.rs-dropdown-menu .rs-dropdown-item-divider {
    height: 1px;
    margin: 6px 0;
    overflow: hidden;
    background-color: #e5e5ea;
}

rs-modal-backdrop .fade .in {
    opacity: 1 !important;
}