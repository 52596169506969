// Variable overrides
$primary-base: #E7C724 !default;
$primary-100: #eae9fb !default;
$primary-200: #d6d2fb !default;
$primary-300: #c1bcf4 !default;
$primary-400: #ada5f1 !default;
$primary-500: #988fed !default;
$primary-600: #8478ea !default;
$primary-700: #E7C724 !default;
$primary-800: #E7C724 !default;
$primary-900: #E7C724!default;
$primary-dark: #E7C724 !default;
$primary: #E7C724 !default;